import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // You'll need to install this package

import styles from './Button.module.css';

const CountdownTimer = () => {
  const readInitialCountdown = () => {
    if (typeof window !== 'undefined') {
      return parseInt(Cookies.get('countdown')) || 60;
    }
    return 60;
  };

  const [countdown, setCountdown] = useState(readInitialCountdown);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
          Cookies.set('countdown', countdown - 1);
        } else {
          clearInterval(intervalId);
          setIsRunning(false);
        }
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown, isRunning]);

  const handleReset = () => {
    setCountdown(60);
    setIsRunning(true);
    Cookies.set('countdown', 60);
  };

  const handleStop = () => {
    setIsRunning(false);
  };

  const handleResume = () => {
    setIsRunning(true);
  };

  return (
    <div className={styles.timer}>
      <div>Countdown: {countdown} seconds</div>
      <button onClick={handleReset} className={styles.btn}>Reset</button>
      {isRunning ? (
        <button onClick={handleStop} className={styles.btn}>Stop</button>
      ) : (
        <button onClick={handleResume} className={styles.btn}>Resume</button>
      )}
    </div>
  );
};

export default CountdownTimer;
