import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import styles from "./Home.module.css";

const client = axios.create({
  baseURL: "http://127.0.0.1:8000",
});

function SectionOverview() {

  document.title = 'Test Section'

  const { testId, testSection } = useParams();

  const [allQuestions, setAllQuestions] = useState();

  useEffect(() => {
    client
      .get("api/questions/")
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <header>
        {/* <div class="logo">Test Prep</div> */}
        <div className={styles.navigation}>
          <ul className={styles["navigation-ul"]}>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/practice-tests">Practice Tests</a>
            </li>
            <li>
              <a href="/articles">Articles</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
      </header>

      <div className={styles["test-section"]}>
        <div className="text-3xl font-bold">You are about to start the following section: {testSection} </div>
        <div>Click start to begin.</div>
        <Link to="start/1" className="text-blue-500">
          START
        </Link>

        {/* how do I just add '/1' to the url, w/o 'start'? */}



      </div>
    </div>
  );
}

export default SectionOverview;
