import {useEffect, useState} from 'react';
import axios from 'axios';
import {Routes, Route } from 'react-router-dom';
import Test from './components/Test';
import Home from './components/Home';
import SectionOverview from './components/SectionOverview';
import SectionQuestion from './components/SectionQuestion';
import RecorderWrapper from './components/RecorderWrapper';
import Test5 from './components/Test5';

import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
// Initialize React Ga with your tracking ID
ReactGA.initialize('G-9GZ1H2NSZX');

function App() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/test/:testId' element={<Test />} />
      <Route path='/test/:id/:testSection' element={<SectionOverview />} />
      <Route path='/test/:testId/:testSection/:questionId' element={<SectionQuestion />} />
      <Route path='/test/:testId/:testSection/start/:questionId' element={<SectionQuestion />} />

      <Route path='/voice-recorder' element={<RecorderWrapper />} />

      <Route path='/test/5/'element={<Test5 />} />

      {/* <Route path='/test/:id/:testSection' element={<Listening />} />
      <Route path='/test/:testId/:testSection/:questionId' element={<ListeningQuestion />} />
      <Route path='/test/:testId/:testSection/start/:questionId' element={<ListeningQuestion />} /> */}

      {/* <Route path='/test/1/reading/start/:id' element={<ReadingQuestion />} /> */}
      {/* <Route path='test/1/reading/start/1' element={<ReadingQuestion />} /> */}
    </Routes>
  );
}

export default App;
