import React from "react";
import RecorderWrapper from "./RecorderWrapper";
// import styles from "./SpeakingQuestion.module.css";
import styles from './ListeningReadingQuestion.module.css';


function SpeakingQuestion({
  question,
  testSection,
  playAudio,
  q,
  questionNumber,
  audioFile,
}) {
  // console.log("UWUW: ", questionNumber);
  return (
    <div key={parseInt(questionNumber)} className={styles.container}>
      <div className={styles.text}>
        {question}
        <br/>
        {q.text} <br></br>
        {q.section.toLowerCase() === "speaking" && q.audio_file !== "" && (
          <div>
          <audio controls controlsList="nodownload">
            <source
              src={q.audio_file}
              type="audio/mpeg"
              />
          </audio>
          </div>
        )}
      </div>
      <div className={styles.answers}>
        {/* {testSection === "speaking" && audioFile} */}
        <RecorderWrapper />
      </div>
    </div>
  );
}

export default SpeakingQuestion;
