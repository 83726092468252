import MicRecorder from "mic-recorder-to-mp3"
import { useEffect, useState, useRef } from "react"
import axios from "axios"

function RecorderWrapper() {
  // mic-recorder-to-mp3
  const recorder = useRef(null); // recorder
  const audioPlayer = useRef(null); // ref for the html audio tag
  const [blobURL, setBlobUrl] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [isRecording, setIsRecording] = useState(null);


  useEffect(() => {
    // declaring the recorder obj and storing it inside ref
    recorder.current = new MicRecorder({ bitRate: 128 })
  }, [])

  const startRecording = () => {
    // checking if recording isn't blocked by browser
    recorder.current.start()
      .then(() => {
        setIsRecording(true)
      })
  };

  const stopRecording = () => {
    recorder.current
      .stop()
      .getMp3()
      .then(([buffer,  blob]) => {
        const file = new File(buffer, 'audio.mp3', {
          type: blob.type,
          lastModified: Date.now()
        })
        const newBlobUrl = URL.createObjectURL(blob)
        setBlobUrl(newBlobUrl)
        setIsRecording(false)
        setAudioFile(file)

        let alink = document.createElement('a');
        alink.href = newBlobUrl;
        alink.download = 'audio.mp3';
        alink.click();

      })
      .catch((e) => console.log(e))
  }


  const saveHandler = () => {
    const data = audioFile;
  };


  const [uploadURL, setUploadURL] = useState('');



  return (
    <div>
      <h1>Voice Recorder</h1>
      <audio ref={audioPlayer} src={blobURL} controls='controls' />
      <div>
        <button disabled={isRecording} onClick={startRecording} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-5 my-5 rounded">START</button>
        <button disabled={!isRecording} onClick={stopRecording} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-5 my-5 rounded">STOP AND DOWNLOAD</button>

        {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-5 my-5 rounded">SUBMIT</button>
        <button onClick={saveHandler} className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-5 my-5 py-2 px-4 rounded">Save file</button> */}
      </div>
    </div>  );
};

export default RecorderWrapper;