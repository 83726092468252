import React from "react";
import { Link, useParams } from "react-router-dom";

import styles from "./Home.module.css";

function Test() {
  document.title = "TOEFL - Available Test Sections";

  const { testId } = useParams();

  return (
    <div className="w-screen h-screen bg-gray-100 px-10 mx-0">
      <head>
        <link rel="icon" href="static/favicon.ico" />
      </head>
      <header>
        {/* <div class="logo">Test Prep</div> */}
        <div className={styles.navigation}>
          <ul className={styles["navigation-ul"]}>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/practice-tests">Practice Tests</a>
            </li>
            <li>
              <a href="/articles">Articles</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
      </header>

      <div className={styles["test-section"]}>
        <div className="text-3xl font-bold">Test num. {testId}</div>
        <div className="underline text-blue-500">
          <Link to="reading/">Reading</Link>
        </div>
        <div className="underline text-blue-500">
          <Link to="listening/">Listening</Link>
        </div>
        <div className="underline text-blue-500">
          <Link to="speaking/">Speaking</Link>
        </div>
        <div className="underline text-blue-500">
          <Link to="writing/">Writing</Link>
        </div>
      </div>
    </div>
  );
}

export default Test;
