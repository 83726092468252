import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import sound from "../audio/edmg_alt_src.mp3";
import RecorderWrapper from "./RecorderWrapper";
import CountdownTimer from "./CountdownTimer";
import ListeningReadingQuestion from "./ListeningReadingQuestion";
import WritingQuestionQ1 from "./WritingQuestionQ1";
import WritingQuestionQ2 from "./WritingQuestionQ2";
import SpeakingQuestion from "./SpeakingQuestion";

import questionData from "./questions.json"

import styles from "./SectionQuestion.module.css";
import styles2 from "./Home.module.css";

const client = axios.create({
  baseURL: "http://127.0.0.1:8000",
});



function SectionQuestion() {
  document.title = 'TOEFL Test'
  const { testId, testSection, questionId } = useParams();
  // console.log(testId, testSection, questionId);

  let nextQuestionId = parseInt(questionId) + 1;

  const [allQuestions, setAllQuestions] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showResult, setShowResult] = useState({
    ans: 'After you click "Check answer", the result will be displayed here: ',
    result: false,
  });

  const [formData, setFormData] = useState("");
  const [newAnswers, setNewAnswers] = useState([]);


  // Retrieve the state from localStorage if it exists, or use default values
  const storedScoreListeningReading = localStorage.getItem('scoreListeningReading');
  const initialScoreListeningReading = storedScoreListeningReading
    ? JSON.parse(storedScoreListeningReading)
    : [
        { section: "listening", currentScore: 0 },
        { section: "reading", currentScore: 0 },
      ];

  // Use localStorage data as the initial state
  const [scoreListeningReading, setScoreListeningReading] = useState(initialScoreListeningReading);



  // Update the state and store it in a useEffect
  const updateScoreListeningReading = useCallback(() => {
    setScoreListeningReading((prevState) => {
      return prevState.map((item) => {

        if (item.section === testSection) {
          return { ...item, currentScore: item.currentScore + 1 };
        }
        return item;
      });
    });
  }, [testSection]);

  useEffect(() => {
    // Store the updated state in localStorage
    localStorage.setItem('scoreListeningReading', JSON.stringify(scoreListeningReading));
  }, [scoreListeningReading]);
  // const [scoreListeningReading, setScoreListeningReading] = useState([
  //   { section: "listening", currentScore: 0 },
  //   { section: "reading", currentScore: 0 },
  // ]);

  // const handleChangeWriting = (e) => {
  //   setFormData(e.target.value);
  // };

  const handleFormSubmitWriting = (e) => {
    e.preventDefault();
    axios
      .put(
        `http://127.0.0.1:8000/api/question/${
          "Test " + testId
        }/${testSection}/${questionId}/update_answer/`,
        { answer: formData }
      )
      .then((response) => {
        // You can add further logic here, such as updating the UI.
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    client
      .get("api/questions/")
      .then((res) => {
        setAllQuestions(res.data);
      })
      .catch((err) => {
      });
  }, []);

  const [jsonQuestions, setJsonQuestions] = useState([]);

  useEffect(() => {
    // Directly setting the state with the imported data
    setJsonQuestions(questionData);
  }, []); // The empty dependency array ensures this effect runs only once after the initial render


  const submitAnswerHandler = (event) => {
    event.preventDefault();

    // console.log(newAnswers);
    
    if (newAnswers.includes("!!!")) {
      let splitSelectedAnswer = newAnswers.split('#')
      let unfusedSelectedAndCorrect = splitSelectedAnswer[splitSelectedAnswer.length - 1].split("!!!")[0]
      // console.log('unfusedSelectedAndCorrect: ', unfusedSelectedAndCorrect);
      // console.log(splitSelectedAnswer);
      let mergedAnswers = splitSelectedAnswer.slice(0, splitSelectedAnswer.length - 1).concat(unfusedSelectedAndCorrect);
      // console.log("mergedAnswers: ", mergedAnswers);

      let actualAnswers = splitSelectedAnswer[splitSelectedAnswer.length - 1].split("!!!")[1].split("@")
      // console.log('actualAnswers: ', actualAnswers)


      // array comparison

      if (mergedAnswers.length !== actualAnswers.length) {
        // console.log('WRONG_1')
        return false;
      }
      
      // Sort both arrays
      const sortedMergedAnswers = mergedAnswers.slice().sort();
      const sortedActualAnswers = actualAnswers.slice().sort();
      
      // Compare sorted arrays
      for (let i = 0; i < sortedMergedAnswers.length; i++) {
        if (sortedMergedAnswers[i] !== sortedActualAnswers[i]) {
          // console.log('WRONG_2')
          setShowResult({ ans: "The answer is wrong!", result: false })
          return false;
        }
      }
      
      // console.log('RIGHT')
      setShowResult({ ans: "The answer is correct! Good job.", result: true })
      updateScoreListeningReading();
      return true;

    }

    let splitSelectedAnswer = selectedAnswer.split("#");
    // console.log(splitSelectedAnswer);

    if (splitSelectedAnswer[1] === splitSelectedAnswer[2]) {
      setShowResult({ ans: "The answer is correct! Good job.", result: true });

      updateScoreListeningReading();
    } else {
      setShowResult({ ans: "The answer is wrong!", result: false });
    }
  };

  const onOptionChange = (event) => {
    setSelectedAnswer(event.target.value);
  };

  const audioClickHandler = () => {
    audio.play();
  };

  let playAudio = (
    <div>
      <button onClick={audioClickHandler}>Click here to play the audio</button>
    </div>
  );
  let audio = new Audio(sound);

  const recordClickHandler = () => {};

  let recordSpeech = (
    <div>
      <button onClick={recordClickHandler}>
        Click here to start recording your answer
      </button>
    </div>
  );

  const nextQuestionAnswerReset = () => {
    setShowResult({
      ans: 'After you click "Check answer", the result will be displayed here: ',
      result: false,
    });
  };


  const isNextQuestionVisible = () => {
    if (testSection === "reading" && parseInt(questionId) >= 20) {
      return false;
    } else if (testSection === "listening" && parseInt(questionId) >= 28) {
      return false;
    } else if (testSection === "speaking" && parseInt(questionId) >= 4) {
      return false;
    } else if (testSection === "writing" && parseInt(questionId) >= 2) {
      return false;
    }
    return true;
  };

  return (
    <div className="w-screen h-screen bg-gray-100 px-10 mx-0">
      <header>
        {/* <div class="logo">Test Prep</div> */}
        <div className={styles2.navigation}>
          <ul className={styles2["navigation-ul"]}>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/practice-tests">Practice Tests</a>
            </li>
            <li>
              <a href="/articles">Articles</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
      </header>

      <div className={styles2["left-side-body"]}>
        {questionData.map((q) => {
          if (
            q.practice_test_number === parseInt(testId) &&
            q.question_number === parseInt(questionId) &&
            q.section.toLowerCase() === testSection &&
            q.section.toLowerCase() !== "speaking" &&
            q.section.toLowerCase() !== "writing"
          ) {
            return (
              <ListeningReadingQuestion
                key={q.question_number}
                question={q.question}
                audioFile={q.audio_file}
                onOptionChange={onOptionChange}
                submitAnswerHandler={submitAnswerHandler}
                showResult={showResult}
                q={q}
                setNewAnswers={setNewAnswers}
              />
            );
          } else if (
            q.practice_test_number === parseInt(testId) &&
            q.question_number === parseInt(questionId) &&
            q.section.toLowerCase() === testSection &&
            q.section.toLowerCase() === "speaking"
          ) {
            return (
              <SpeakingQuestion
                key={q.question_number}
                question={q.question}
                questionNumber={q.question_number}
                testSection={testSection}
                playAudio={playAudio}
                audioFile={q.audio_file}
                q={q}
              />
            );
          } else if (
            q.practice_test_number === parseInt(testId) &&
            q.question_number === parseInt(questionId) &&
            q.question_number === 1 &&
            q.section.toLowerCase() === testSection &&
            q.section.toLowerCase() === "writing"
          ) {
            return (
              <WritingQuestionQ1
                key={q.question_number}
                question={q.question}
                testSection={testSection}
                playAudio={playAudio}
                audioFile={q.audio_file}
                q={q}
              />
            );
          } else if (
            q.practice_test_number === parseInt(testId) &&
            q.question_number === parseInt(questionId) &&
            q.question_number === 2 &&
            q.section.toLowerCase() === testSection &&
            q.section.toLowerCase() === "writing"
          ) {
            return (
              <WritingQuestionQ2
                key={q.question_number}
                question={q.question}
                testSection={testSection}
                q={q}
                // playAudio={playAudio}
              />
            );
          }
        })}

        <CountdownTimer />

        <div className={styles.score}>
        {isNextQuestionVisible() && (
        <Link
          onClick={nextQuestionAnswerReset}
          to={`/test/${testId}/${testSection}/start/${nextQuestionId}`}
        >
          Next - question {nextQuestionId}, test {testId}{" "}
        </Link>
      )}
          <div>
            Your current score in listening is:{" "}
            {scoreListeningReading[0].currentScore}
          </div>
          <div>
            Your current score in reading is:{" "}
            {scoreListeningReading[1].currentScore}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionQuestion;