import { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import styles from "./Home.module.css";

const client = axios.create({
  baseURL: "http://127.0.0.1:8000",
});

function App() {

  document.title = "AllTestsPrep"

  const [allQuestions, setAllQuestions] = useState([]);
  const [allTests, setAllTests] = useState([]);

  useEffect(() => {
    client
      .get("/api/tests/")
      .then((res) => {
        console.log(res.data);
        setAllTests(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    client
      .get("/api/questions/")
      .then((res) => {
        console.log(res.data);
        setAllQuestions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={styles["main-section"]}>
    <header>
        {/* <div class="logo">Test Prep</div> */}
        <div className={styles.navigation}>
            <ul className={styles['navigation-ul']}>
                <li><a href="/">Home</a></li>
                <li><a href="/practice-tests">Practice Tests</a></li>
                <li><a href="/articles">Articles</a></li>
                <li><a href="/contact">Contact</a></li>
            </ul>
        </div>
    </header>

      <div className={styles["div-m"]}>
        
        <h1>Welcome to AllTestsPrep!</h1>
        
        <h1>
          Why you should use this website
        </h1>
        Here you can practice mocks tests and effectively
        prepare you for the real exam. The practice tests cover all four
        sections—listening, reading, speaking, and writing—providing an
        immersive experience to familiarize you with the format and structure of
        the actual test. You will receive instant feedback on your performance,
        pinpointing areas for improvement and allowing you to track your
        progress over time.
        Moreover, you can learn how to prepare for each section by learning effective tips for studying for the exam in one of many of our articles.
      </div>

      <div className={styles["div-m"]}>
        <h1>
          Why you should do practice tests
        </h1>
        At first glance TOEFL might seem deceptively easy. However, in reality
        it is pretty challenging and you should practice as much as you can if
        you want to get a high score. This platform will help you with this.
      </div>

      <div className={styles["div-m"]}>
        <h1>What is TOEFL?</h1>
        The Test of English as a Foreign Language (TOEFL) is a standardized test
        designed to assess the English language proficiency of non-native
        speakers. Widely recognized by academic institutions and employers
        worldwide, TOEFL measures the test-taker's ability to understand and use
        English in academic settings. The exam evaluates listening, reading,
        speaking, and writing skills through tasks such as reading
        comprehension, listening to lectures, and expressing opinions on various
        topics. TOEFL is a crucial requirement for non-native English speakers
        seeking admission to English-speaking universities and colleges.
      </div>

      <div>Here is what we can help you with:</div>

      <div className={styles.services}>
      <div className={styles.service}>
          <div className={styles.icon}>
            <img src="static/images/d1.png" alt="" />
          </div>
          <h2>Free Tests</h2>
          Practice TOEFL using our tests and get the score of your dreams
        </div>
        <div className={styles.service}>
          <div className={styles.icon}>
            <img src="static/images/d2.png" alt="" />
          </div>
          <h2>Tips on writing essays</h2>
          Follow our guides to get better at writing integrated essays and academic discussion tasks 
        </div>
        <div className={styles.service}>
          <div className={styles.icon}>
            <img src="static/images/d3.png" alt="" />
          </div>
          <h2>Note-taking strategies</h2>
          Learn how to effectively take notes during your exam
        </div>
        <div className={styles.service}>
          <div className={styles.icon}>
            <img src="static/images/d4.png" alt="" />
          </div>
          <h2>Strategies for answering listening tasks</h2>
          Use our guides to get the highest score
        </div>
        <div className={styles.service}>
          <div className={styles.icon}>
            <img src="static/images/d5.png" alt="" />
          </div>
          <h2>Tips for tackling reading questions</h2>
          Our strategies will help you significantly improve your reading score
        </div>
        <div className={styles.service}>
          <div className={styles.icon}>
            <img src="static/images/d6.png" alt="" />
          </div>
          <h2>Speaking tips</h2>
          Use our speaking templates to answer questions coherently and effectively
        </div>
      </div>

      {/* <div className={styles["div-m"]}>
        <h1>TOEFL sections</h1>
        <div className={styles['test-section']}>
          1. Reading
          <ul>
            <li>
              <span className={styles['section-info']}>Length:</span> About 60-80
              minutes
            </li>
            <li>
              <span className={styles['section-info']}>Tasks:</span> It includes 3-4
              passages from university-level textbooks. Each passage is about
              700 words long. Test-takers must answer 10 questions per passage.
            </li>
            <li>
              <span className={styles['section-info']}>Skills tested:</span>{" "}
              Understanding of rhetorical functions such as cause-effect,
              compare-contrast and argumentation; identifying main ideas and
              details; understanding vocabulary in context; making inferences.
            </li>
          </ul>
        </div>

        <div className={styles['test-section']}>
          2. Listening
          <ul>
            <li>
              <span className={styles['section-info']}>Length: </span>About 60-90
              minutes
            </li>
            <li>
              <span className={styles['section-info']}>Tasks: </span>This section
              includes 4-6 lectures (some with classroom discussion) and 2-3
              conversations. Each lecture is 3-5 minutes long, and each
              conversation is about 3 minutes.
            </li>
            <li>
              <span className={styles['section-info']}>Skills tested: </span>
              Understanding the attitude of the speaker, identifying the main
              ideas and important details, understanding organization of
              information and relationships between ideas.
            </li>
          </ul>
        </div>

        <div className={styles['test-section']}>
          3. Speaking
          <ul>
            <li>
              <span className={styles['section-info']}>Length: </span>20 minutes
            </li>
            <li>
              <span className={styles['section-info']}>Tasks: </span>Includes two
              independent tasks (express an opinion on a familiar topic) and
              four integrated tasks (read, listen, and then speak in response to
              a question).
            </li>
            <li>
              <span className={styles['section-info']}>Skills Tested: </span>{" "}
              Ability to speak English effectively in academic settings, clearly
              organize ideas, and use grammar and vocabulary accurately.
            </li>
          </ul>
        </div>
        <div className={styles['test-section']}>
          4. Writing
          <ul>
            <li>
              <span className={styles['section-info']}>Length: </span>50 minutes
            </li>
            <li>
              <span className={styles['section-info']}>Tasks: </span>Consists of two
              tasks. In the integrated task, test-takers read a passage, listen
              to a lecture, then write a response. In the independent task, they
              write an essay based on personal experience or opinion.
            </li>
            <li>
              <span className={styles['section-info']}>Skills tested: </span>Quality
              and effectiveness of writing, organization of ideas, and accuracy
              of grammar and vocabulary.
            </li>
          </ul>
        </div>
      </div> */}

      {/* <div className="text-3xl font-bold underline">Choose a test:</div> */}
      <div>
        {allTests.map((entry) => {
          return (
            <Link
              to={"test/" + entry.practice_test_number}
              key={Math.random()}
              className="underline text-blue-500"
            >
              {entry.practice_test} <br></br>
            </Link>

            // <Link to="test/" key={Math.random()}>
            //   {entry.practice_test} <br></br>
            // </Link>
          );
        })}
      </div>

      <br></br>

      <div>
        {allQuestions.map((entry) => {
          return <div key={Math.random()}>{entry.question}</div>;
        })}
      </div>
    </div>
  );
}

export default App;
