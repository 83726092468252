import React, { useState } from "react";
import { saveAs } from "file-saver";
// import styles from "./WritingQuestionQ1.module.css";
import styles from './ListeningReadingQuestion.module.css';

function WritingQuestionQ1({
  question,
  testSection,
  playAudio,
  audioFile,
  q
}) {
  const [text, setText] = useState(""); // State to hold the text content
  const [downloadLink, setDownloadLink] = useState(null);

  // console.log(audioFile)

  const handleExport = () => {
    // Create a Blob containing the text data
    const textBlob = new Blob([text], { type: "text/plain" });

    // Create a download link and trigger the download
    const url = URL.createObjectURL(textBlob);
    setDownloadLink(url);
  };

  const handleDownload = () => {
    if (downloadLink) {
      saveAs(downloadLink, "document.txt");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div>{question}</div>
        {/* {testSection === "writing" && playAudio} */}

        {q.section.toLowerCase() === "writing" && (
          <div>
            {q.text}
            <br></br>
        <audio controls controlsList="nodownload">
          <source src={q.audio_file} type="audio/mpeg" />
        </audio>
          </div>
      )}
      </div>

      <div className={styles.answers}>
        <div>Enter your text below:</div>
        <div>
          <textarea
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter your answer here"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          ></textarea>
          <div>
            <button onClick={handleExport} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-5 my-5 rounded">Export to .txt</button>
            <button onClick={handleDownload} disabled={!downloadLink} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-5 my-5 rounded">
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WritingQuestionQ1;
