import React from 'react';
import { Link, useParams } from 'react-router-dom';

function Test5() {
  const { testId } = useParams();

  return (
    <div className='w-screen h-screen bg-gray-100 px-10 mx-0'>
      TESTING TEST 5 AS A SEPARATE PAGE
      <div className="text-3xl font-bold">Test num. { testId }</div>
      <div className='underline text-blue-500'><Link to='reading/'>Reading</Link></div>
      <div className='underline text-blue-500'><Link to='listening/'>Listening</Link></div>
      <div className='underline text-blue-500'><Link to='speaking/'>Speaking</Link></div>
      <div className='underline text-blue-500'><Link to='writing/'>Writing</Link></div>
    </div>
  )
}

export default Test5;