import React from "react";
import styles from './ListeningReadingQuestion.module.css';
import Button from "./Button";

// import myAudioFile from '../public/audio/edmg.mp3';

import questionData from "./questions.json";

import { useEffect, useState } from "react";


function ListeningReadingQuestion({
  question,
  audioFile,
  onOptionChange,
  submitAnswerHandler,
  showResult,
  q,
  highlights,
  setNewAnswers
}) {
  // console.log(q);
  // console.log("audioFile: ", audioFile);
  // console.log("q.audio_file: ", q.audio_file);
  // console.log("process.env.PUBLIC_URL: ", process.env.PUBLIC_URL)

  const [selectedAnswers, setSelectedAnswers] = useState([]);

  

  highlights = q.highlights

  useEffect(() => {
    // Perform the check only if the audioFile is not empty
    if (q.audio_file) {
      fetch(q.audio_file, { method: 'HEAD' })
        .then((response) => {
          if (response.ok) {
            console.log("The audio file exists and is accessible.");
          } else {
            throw new Error('The audio file does not exist or is not accessible.');
          }
        })
        .catch((error) => {
          console.error("Error fetching the audio file: ", error.message);
        });
    }
  }, [q.audio_file]); // This will run the check whenever q.audio_file changes

  // console.log(highlights);

  // const audioFileTmp = questionData.audio_file;
  // console.log(audioFileTmp);

  function escapeRegExp(text) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
  }
  
  function highlightText(text, highlights) {
    if (!highlights) {
      return text;
    }
  
    // Split the highlights string into an array
    const highlightArray = highlights
      .split('#')
      .map(h => h.trim())
      .filter(h => h);
      // console.log('highlightArray:', highlightArray);
  
    // Separate words and sentences
    const words = highlightArray.filter(h => !h.includes(' ')).map(escapeRegExp);
    const sentences = highlightArray.filter(h => h.includes(' ')).map(escapeRegExp);
    // console.log('words: ', words)
    // console.log('sentences: ', sentences)
  
    // Create a combined regex for words and sentences
    const regexParts = words.map(word => `\\b${word}\\b`);
    // console.log('regexParts: ', regexParts);
    regexParts.push(...sentences);
    // console.log('regexParts: ', regexParts);
    const regex = new RegExp(`(${regexParts.join('|')})`, 'gi');
  
    const parts = text.split(regex);
    // console.log('parts', parts);
    // console.log('regex', regex);
  
    return parts.map((part, index) => (
      regex.test(part)
        ? <span key={index} className={styles.highlight}>{part}</span>
        : part
    ));
  }

  const renderInputType = () => {
    const optionsCount = Object.keys(q).filter(key => key.startsWith('option')).length;
    const inputType = optionsCount > 4 ? 'checkbox' : 'radio';
    console.log('inputType: ', inputType)
    return inputType;
 };
  
 useEffect(() => {
  // Construct the concatenatedAnswers string here
  if (Array.isArray(q.answer)) {
    const correctAnswersJoined = q.answer.join("@")
    const concatenatedAnswers = selectedAnswers.join('#') + "!!!" + correctAnswersJoined;
    // console.log("HNNNNNNNNNNNNG")
    setNewAnswers(concatenatedAnswers)
  }
 // !!! - this makes parts of the code in handleCheckboxChange redundant? Since this is where newAnswers are updated

  // const concatenatedAnswers = selectedAnswers.join('#') + "!!!" + q.answer;
  // console.log("concatenatedAnswers: ", concatenatedAnswers);
  // setNewAnswers(concatenatedAnswers); // Move the state update here
}, [selectedAnswers, q.answer, setNewAnswers]); // Add dependencies
 
 const handleCheckboxChange = (event) => {
  const { value, checked } = event.target;
  setSelectedAnswers(prevAnswers => {
    if (checked) {
      // If checked, add the value to the array
      const newAnswers = [...prevAnswers, value];
      const correctAnswersJoined = q.answer.join("@")
      // console.log('correctAnswersJoined:', correctAnswersJoined);
      const concatenatedAnswers = newAnswers.join('#') + "!!!" + correctAnswersJoined;
      // const concatenatedAnswers = newAnswers.join('#') + "!!!" + q.answer;
      // console.log("concatenatedAnswers: ", concatenatedAnswers);
      // setNewAnswers(concatenatedAnswers) // this causes a warning
      return newAnswers;
    } else {
      // If unchecked, remove the value from the array
      const newAnswers = prevAnswers.filter(answer => answer !== value);
      const concatenatedAnswers = newAnswers.join('#') + "!!!" + q.answer;
      // console.log("concatenatedAnswers: ", concatenatedAnswers);
      // setNewAnswers(concatenatedAnswers)
      return newAnswers;
    }
  });
};

 
 
 

  const renderOptions = () => {
    const inputType = renderInputType();
    const options = Object.keys(q).filter(key => key.startsWith('option')).map((optionKey, index) => {
      const optionValue = q[optionKey];
      return (
        <div key={index}>
          <input
            className="m-1"
            type={inputType}
            id={optionValue}
            name="option"
            data-option={optionValue}
            data-answer={q.answer}
            value={inputType === "checkbox" ? optionValue : q.question + "#" + optionValue + "#" + q.answer}
            onChange={inputType === "checkbox" ? handleCheckboxChange : onOptionChange}
          />
          <label htmlFor={optionValue}>{optionValue}</label>
        </div>
      );
    });
    return options;
 };



  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {/* {q.text} */}
        {/* <div className="text-3xl font-bold">{question}</div> */}
        {highlightText(q.text, highlights)}
        {q.section.toLowerCase() === "listening" && q.audio_file !== "" && (
        
        <audio controls controlsList="nodownload">
          {/* <source src={"https://sea-lion-app-fyxtn.ondigitalocean.app" + q.audio_file} type="audio/mpeg" /> */}
          <source src={process.env.PUBLIC_URL + q.audio_file} type="audio/mpeg" />
          {/* <source src={q.audio_file} type="audio/mpeg" /> */}
        </audio>
      )}
      </div>

    <div key={q.question_number} className={styles.answers}>
      {/* <div className="text-3xl font-bold">{question} {q.audio_file}</div> */}
      <div className="text-3xl font-bold">{question}</div>
    

      <form onSubmit={submitAnswerHandler}>
      {renderOptions()}
        <div>
          <button
            type="submit"
            className={styles.btn}
          >
            Check answer
          </button>
        </div>
      </form>

      <div>{showResult.ans}</div>
    </div>
    </div>

  );
}

export default ListeningReadingQuestion;